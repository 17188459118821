import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { SearchBarData } from 'components/SearchBar';
import withAuth from 'components/withAuth';
import withRole from 'components/withRole';
import { actions as formActions, selectors as formSelectors } from 'lib/redux/form';
import { Viewer } from 'types';
import Component from './component';

const { updateForm } = formActions;
const { getForm } = formSelectors;

const formId = 'accommodation-search';

interface Props {
  viewer: Viewer;
}

const AccommodationSearchContainer = ({ viewer }: Props) => {
  const dispatch = useDispatch();
  const formValues = useSelector(getForm(formId));
  const { push } = useRouter();

  const onSubmit = (data: SearchBarData) => {
    push({
      pathname: '/accommodations',
      query: {
        location: data.location?.query,
        lat: data.location?.lat,
        lng: data.location?.lng,
        startDate: data.startDate,
        endDate: data.endDate,
        maxBudget: data.maxBudget,
        minBudget: data.minBudget,
      },
    });
    dispatch(updateForm(formId, data));
  };

  return <Component viewer={viewer} onSubmit={onSubmit} initialData={formValues} />;
};

export default withAuth(
  withRole(AccommodationSearchContainer, {
    role: 'guest',
    redirectPath: '/accommodations',
  })
);
