import { useRouter } from 'next/router';
import { Viewer } from 'types';

type WrappedComponentProps = {
  viewer: Viewer;
  children?: React.ReactNode;
};

type WrapperOpts = {
  role: string;
  redirectPath: string;
};

type WithRoleWrapperType = (
  component: React.ComponentType<WrappedComponentProps>,
  opts: WrapperOpts
) => React.ComponentType<WrappedComponentProps>;

interface Props {
  viewer: Viewer;
}

const WithRoleWrapper: WithRoleWrapperType = (WrappedComponent, { role, redirectPath }) => {
  const WithRole: React.ComponentType<Props> = ({ viewer }) => {
    const { push } = useRouter();

    if (viewer.role?.name?.toUpperCase() !== role.toUpperCase()) {
      push({ pathname: redirectPath });
      return <div />;
    }

    return <WrappedComponent viewer={viewer} />;
  };

  return WithRole;
};

export default WithRoleWrapper;
