import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Head from 'components/Head';
import { Image } from 'components/Image';
import Layout from 'components/Layout';
import SearchBar, { MobileSearchBar, SearchBarData } from 'components/SearchBar';
import Skeleton from 'components/Skeleton';
import { Viewer } from 'types';

interface Props {
  onSubmit: (data: SearchBarData) => void;
  viewer: Viewer;
  initialData?: SearchBarData;
}

const AccommodationSearch = ({ viewer, onSubmit, initialData }: Props) => {
  const intl = useIntl();
  const [data, setData] = useState(initialData || {});

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  return (
    <Layout
      viewer={viewer}
      backgroundImage={
        <Image
          className="object-cover absolute inset-0 w-full h-full"
          src="/images/girl_in_bed.svg"
          alt=""
          fill
          placeholder="blur"
          blurDataURL="data:image/svg+xml;base64,rJCGy2%K_MaLTJIVkVRjox9EIVo$jE-;%L%LxuWAOEt6xat8s8NGIoRPWC-XspRhInWYWBofxutQVWWEtSt6kVxasnIoM|x^NYWTxcRPRjf8j[xa"
        />
      }
    >
      <Head
        title={intl.formatMessage({
          id: 'search_accommodations.head',
          defaultMessage: 'Search',
        })}
      />

      <div className="mx-auto max-w-7xl space-y-14 py-12 px-2 md:px-0grid place-content-center">
        <h2 className="text-3xl text-white sm:text-4xl lg:text-5xl font-serif font-medium text-center">
          {viewer ? (
            <FormattedMessage
              id="search_accommodations.welcome_message"
              defaultMessage="<line>Hey {firstName},</line><line>what are you looking for?</line>"
              values={{
                firstName: viewer?.profile?.firstName,
                line: (chunk) => <div>{chunk}</div>,
              }}
            />
          ) : (
            <Skeleton className="h-8" />
          )}
        </h2>

        <SearchBar data={data} onSubmit={onSubmit} className="rounded-2xl hidden lg:flex" />

        <div className="lg:hidden">
          <MobileSearchBar data={data} onSubmit={onSubmit} className="shadow-none rounded-2xl" />
        </div>
      </div>
    </Layout>
  );
};

export default AccommodationSearch;
